html {
  box-sizing: border-box;
  color: var(--dig-color-font);
  font-size: 10px;
}

body {
  position: relative;
  height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  font-family: var(--dig-typo-family-primary);
  background-color: var(--ls-color-bg-1);
  font-size: var(--dig-font-xl);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-weight: 700;
  font-size: var(--dig-font-4xl);
}

h2 {
  font-weight: 700;
  font-size: var(--dig-font-3xl);
}
h3 {
  font-weight: 700;
  font-size: var(--dig-font-2xl);
}

h4 {
  font-weight: 700;
  font-size: var(--dig-font-xl);
}

h5 {
  font-weight: 700;
  font-size: var(--dig-font-lg);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}
input {
  font-size: var(--dig-font-lg);
  font-family: var(--dig-typo-family-primary);
}

/* 
@media screen and (max-width: $max-width-md) {
  html {
    font-size: 9px;
  }
}

@media screen and (max-width: $max-width-sm) {
  html {
    font-size: 8px;
  }
}
 */

$font-weight-extra-bold: 800;
$font-weight-bold: 700;
$font-weight-semi-bold: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

// Colors
$color-light-1: #fff;
$color-light-2: #f5f5f5;
$color-light-3: #efefef;
$color-light-4: #aeaeae;
$color-light-5: #ddd;
$color-light-6: #d2d2d2;
$color-light-7: #f6f6f6;

$color-grey-01: #6c6c6c;
$color-grey-02: #c8c5ca;
$color-grey-1: #808080;

$color-dark-1: #000;
$color-dark-2: #222;
$color-dark-3: #303030;

$color-fb: #3b5998;
$color-whatsApp: #25d366;
$color-twitter: #1da1f2;
$color-linkedin: #0077b5;
$color-snapchat: #fffc00;
$color-tiktok: #010101;
$color-insta: #893dc2;

// Box shadows

$box-shadow-light: -2px 3px 9px 4px rgb(0 0 0 / 10%);
$box-shadow-soft: 0px 4px 20px rgba(174, 174, 174, 25%);
$box-shadow-1: 0px 0px 30px rgb(174 174 174 / 50%);
// RWD
$width-xxl: 2000px;
$width-xl: 1280px;
$max-width-xl: 1279.98px;
$width-lg: 992px;
$max-width-lg: 991.98px;
$width-md: 768px;
$max-width-md: 767.98px;
$width-sm: 576px;
$max-width-sm: 575.98px;

/*
XS: 0 - 575
SM: 576 - 767
MD: 768 - 991
LG: 992 - 1279
XL: 1280 - 2000
XXL: >2000
*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 2000px,
);

// Setup
$app-desktop-nav-height: 80px;
$app-desktop-sub-nav-height: 48px;
$app-mobile-nav-height: 80px;

$app-desktop-padding: 40px;
$app-mobile-padding: 20px;

// Z-indexs
$app-z-index-default: 500;
$app-z-index-dialog: 1000; // Angular material modal z-index = 1000
$app-z-index-nav: 2000;
$app-z-index-alert: 3000;

// Mixins

// Triangle icon
@mixin icon-triangle($size) {
  .icon-triangle:before,
  .icon-triangle:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }
  .icon-triangle,
  .icon-triangle:before,
  .icon-triangle:after {
    width: $size;
    height: $size;
    border-top-right-radius: 30%;
  }

  .icon-triangle {
    display: inline-block;
    background-color: var(--dig-color-primary);
    text-align: left;

    &.down {
      transform: rotate(-120deg) skewX(-30deg) scale(1, 0.866);
      margin-top: -3px;
    }

    &.up {
      transform: rotate(-180deg) skewX(-30deg) scale(1, 0.866);
    }
  }

  .icon-triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }
  .icon-triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}

@mixin dashedBorder($borderWidth: 5px, $coverage: 100%) {
  // requires relative/absolute parent position,
  //  coverage for custom padding

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: $coverage;
    pointer-events: none;
    left: 0;
    top: 0;
    border-image: url('^assets/borders/dashed_border_box.png') 5% repeat;
    border-width: $borderWidth;
    border-style: solid;
  }
}

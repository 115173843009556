@import '~@angular/material/theming';
@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
@import './global_classes';
@import './tag_overwrite';

:root {
  --dig-typo-family-primary: 'Poppins', sans-serif;
  --dig-typo-family-secondary: 'Poppins', sans-serif; /* not set */

  /*     --dig-font-xsm: clamp(10px, 1.2rem, 1.2rem);*/
  --dig-font-sm: 10px;
  --dig-font-md: 1.2rem;
  --dig-font-lg: 1.4rem;
  --dig-font-xl: 1.8rem;
  --dig-font-2xl: 2.4rem;
  --dig-font-3xl: 4.2rem;
  --dig-font-4xl: 6rem;

  --dig-color-primary: #ffcc00; /* CUSTOMIZABLE - primary */
  --dig-color-primary-contrast: black; /* CUSTOMIZABLE - primary_text  */
  --dig-color-primary-shade: #00661a; /* not set */
  --dig-color-primary-tint: #cee6e5; /* not set */

  --dig-color-secondary: white; /* CUSTOMIZABLE- secondary_text */
  --dig-color-secondary-contrast: black; /* CUSTOMIZABLE - secondary */
  --dig-color-secondary-shade: #2c7ae8; /* not set */
  --dig-color-secondary-tint: #e4eefc; /* not set */

  --dig-color-success: #32cd32; /* not set */
  --dig-color-success-contrast: #ffffff; /* not set */
  --dig-color-success-shade: #3b8a5b; /* not set */
  --dig-color-success-tint: #d6f5d6; /* not set */

  --dig-color-warning: #ffae00; /* not set */
  --dig-color-warning-contrast: #303030; /* not set */
  --dig-color-warning-shade: #e0ac08; /* not set */
  --dig-color-warning-tint: #ffca22; /* not set */

  --dig-color-danger: #ff0000;
  --dig-color-danger-contrast: #ffffff; /* not set */
  --dig-color-danger-shade: #cc0000; /* not set */
  --dig-color-danger-tint: rgb(236 205 205); /* not set */

  --dig-color-medium: #000000; /* CUSTOMIZABLE - background_text */
  --dig-color-medium-contrast: white; /* CUSTOMIZABLE - background*/
  --dig-color-medium-shade: #c8c5ca;
  --dig-color-medium-tint: #f0f1f5;

  --dig-color-font: #000000; /* CUSTOMIZABLE - primary_text */
  --dig-color-font-tint: #c8c5ca; /* not set */

  --dig-color-light-border-1: #c8c5ca;
  --dig-color-light-border-2: #f0f0f0; /* not set */
  --dig-color-dark-border-1: #111111; /* not set */

  --gac-color-background-secondary: white /* CUSTOMIZABLE - background_secondary */;
}

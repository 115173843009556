.btn {
  all: unset;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: var(--dig-font-lg);
  flex-shrink: 0;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  /*   &.w-xsm {
    min-width: 110px;
  } */
  &.w-sm {
    min-width: 14.4rem;
  }
  /*  &.w-md {
    min-width: 2.2rem;
  } */
  &.w-lg {
    min-width: 26.6rem;
  }

  &.h-sm {
    padding: 0.6rem 2rem;
    min-height: 40px;
  }

  &.h-lg {
    padding: 1.7rem 3.7rem;
    min-height: 62px;
  }

  &:hover:not(:disabled) {
    filter: contrast(0.6);
  }
  &:active:not(:disabled) {
    filter: brightness(1.07);
  }
  span {
    /* inlineSVG */
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }

  &:disabled {
    background-color: var(--dig-color-medium-shade) !important;
    color: white;
    cursor: default;
  }
}

button.basic {
  background-color: var(--dig-color-medium-contrast);
  color: var(--dig-color-medium);
  border: 1px solid var(--dig-color-medium);

  /*   &:hover {
    background-color: var(--dig-color-secondary-contrast);
  } */
}

button.primary {
  background-color: var(--dig-color-primary);
  color: var(--dig-color-primary-contrast);
}

button.secondary {
  background-color: var(--dig-color-secondary-contrast);
  color: var(--dig-color-secondary);
}

.underline {
  cursor: pointer;
  font-weight: 600;
  &.primary {
    color: var(--dig-color-primary);
    text-decoration: underline;
  }

  &.basic {
    text-underline-offset: 1rem;
  }
}

.error {
  color: var(--dig-color-danger);
  font-weight: 500;
}

.panel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 51px;
  width: 92vw;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  .container-overflow {
    align-self: flex-start;
  }
  @media screen and (min-width: $width-lg) {
    .container-overflow {
      width: 122%;
      left: -11%;
      position: relative;
    }
  }
  @media screen and (max-width: $width-lg) {
    .container-overflow {
      width: 96vw;
      left: calc((96vw - 100%) / -2);
      position: relative;
    }
  }
}

.snackbar {
  border-radius: 12px !important;
  border: 1px solid;
}

.success-snackbar {
  color: var(--dig-color-success);
  background-color: var(--dig-color-success-contrast);
  border-color: var(--dig-color-success);
}

.info-snackbar {
  color: var(--dig-color-primary);
  background-color: var(--dig-color-success-contrast);
  border-color: var(--dig-color-primary);
}

.error-snackbar {
  color: var(--dig-color-danger-shade);
  background-color: var(--dig-color-danger-tint);
  border-color: var(--dig-color-danger-shade);
}

@media screen and (max-width: $width-sm) {
  .dialog-primary {
    max-width: 100vw !important;

    .mat-dialog-container {
      max-height: 100vh;
    }
  }
}

input[type='text'] {
  /* inputs won't be customizable, so we use different set of colors */
  border-radius: 10px;
  border: 1px solid transparent;
  transition: border 0.3s;
  padding: 1.4rem 1.2rem;
  font-size: var(--dig-font-lg);
  border-color: var(--dig-color-light-border-1);
  background-color: white;
  outline: var(--dig-color-light-border-1);
  font-weight: 400;

  &:hover,
  &:active,
  &:focus {
    border-color: black;
  }

  &.error {
    border-color: var(--dig-color-danger);
    color: var(--dig-color-danger);
  }
}

lib-checkbox.checkbox-static-colors .checkbox-container {
  .checkbox-border {
    border: 2px solid var(--dig-color-medium-shade) !important;
    border-radius: 3px !important;

    .checkbox-filling {
      color: white !important;
      background-color: black !important;
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      border-radius: 2px;
    }
    &:not(.checked):hover {
      border: 2px solid black !important;
      background-color: white !important;
    }
  }
  input:checked + .checkbox-border {
    border: 2px solid var(--dig-color-medium-shade) !important;
  }
}

.form-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 30px;

  @media (min-width: $width-sm) {
    padding: 2.8rem 4.9rem 3.8rem 4.9rem;
    width: 500px;
  }
  @media (max-width: $width-sm) {
    padding: 2.8rem 1rem;
    width: 100%;
  }
}

@media (min-width: $width-md) {
  .mobile {
    display: none;
  }
}
@media (max-width: $max-width-md) {
  .desktop {
    display: none;
  }
}
